<template>
    <div class="type_area">
        <div style="display:flex;padding:20px 0;">
            <el-menu style="width:200px;" class="el-menu-vertical-demo" :unique-opened="true" :default-active="defaultActive" :default-openeds="openIndex" @open="handleOpen" @close="handleClose" text-color="#999999" active-text-color="#1A9DFF">
                <el-submenu :index="''+index" v-for="(item,index) in sideBar" :key="index">
                    <template slot="title">
                        <span>{{item.categoryName}}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item v-for="(items,indexs) in item.childen" @click="handleSelect(items.id)" :key="indexs" :index="index+'-'+indexs">{{items.title}}</el-menu-item>
                        <!-- <el-menu-item index="1-2">选项2</el-menu-item> -->
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
            <div style="flex:1; margin-left:20px; padding:20px; background:#fff; min-height:300px;">
                <h2 style="font-size:18px; font-weight:600; margin-bottom:20px;">{{content.title}}</h2>
                <el-divider></el-divider>
                <div v-html="content.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'helpCenter',
    data() {
        return {
            sideBar: [],
            content: '',
            openIndex: [],
            defaultActive: '',
        }
    },
    created() {
        this.getPcArticle()
          console.log(this.$route.query.id);
          
    },
    mounted() {
    },
    watch: {
        '$route': {
            handler: function (val, oldVal) {
                this.getPcArticle()
            },
            // 深度观察监听
            deep: true
        }
    },
    methods: {
        handleOpen(key, keyPath) {
           
        },
        handleClose(key, keyPath) {
          
        },
        async handleSelect(id) {
            // console.log(key);
            const res = await this.$api.helpCenterDetail({ id })
            if (res.data.code === 200) {
                this.content = res.data.data
            }
        },
        // 获取底部文章
        getPcArticle() {
            this.$api.pcArticle().then((res) => {
                if (res.data.code === 200) {
                    this.sideBar = res.data.data
                    if(+this.$route.query.id){
                        this.openIndex = [this.sideBar.findIndex(item => item.categoryName === this.$route.query.num)+''] 
                         const index = this.sideBar[this.openIndex].childen.findIndex(item1 => item1.id === +this.$route.query.id)
                         console.log(index,'as');
                         this.defaultActive= this.openIndex+'-'+index
                         console.log(this.defaultActive,'asasd');
                         
                        this.handleSelect(+this.$route.query.id)
                    }else{
                        this.handleSelect(res.data.data[0].childen[0].id)
                    }
                }
            });
        },
    }
}
</script>

<style>

</style>